.experiments-grid {
  width: -webkit-fill-available;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: .5rem;

  @media(max-width: 1024px) {
    // padding: 0 2em;
  }

  @media(max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    // padding: 0 2em;
  }
}

.experiment-card {
  border-radius: 0.5em;
  padding: .5em;
  background-color: var(--grey-100);
  border: 1px solid var(--border-color);

  transition: transform 0.3s ease-out;

  &:hover {
    transform: translateY(0) rotate(0deg) !important;
  }

  .experiment-image-container {
    position: relative;
    overflow: hidden;

    border-radius: 0.5em;
    aspect-ratio: 1 / 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.3s ease-in-out;
    }

    .static-image {
      position: absolute;
      top: 0;
      left: 0;
    }

    .hover-gif {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    &:hover {
      .static-image {
        opacity: 0;
      }

      .hover-gif {
        opacity: 1;
      }
    }
  }
}