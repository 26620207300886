.carousel-container-logo {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: var(--max-width-container);
    flex-wrap: wrap;
    gap: 1em;

    @media (max-width:800px) {
        padding-top: 1em;
        gap: .5em;
    }

    img {
        width: auto;
        display: block;
        object-fit: cover;
        opacity: 50%;
        filter: invert(1);
        height: 30px;

        @media (max-width: 768px) {
            height: 25px;
        }
    }
}