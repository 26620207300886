$gap: .5em;
$border-radius: 0.75em;

@mixin flex($direction: column, $gap: $gap) {
  display: flex;
  flex-direction: $direction;
  gap: $gap;
}

.training-parent {
  @include flex(column);
  align-items: center;
  justify-content: center;
  max-width: var(--max-width-container);
  margin: 0 auto; // Center the container
  margin-top: 4em;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.intro {
  display: flex;
  flex-direction: column;
  gap: $gap;
  align-items: center;
  justify-content: center;
  padding: $gap*4 1em;

  h1{
    max-width: 30ch;
  }

  h1,p{
    text-align: center;
  }

  .data {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin: 2em 0;

    @media(max-width:800px) {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }

    .data-points {
      width: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      padding: 1em;
      background-color: var(--grey-100);
      border: 1px solid var(--border-color);
      border-radius: .75em;
      gap: 1em;

      @media (max-width:800px) {
        align-items: center;
      }

      h2,
      p {
        text-align: left;
        margin: 0;
      }

      h2{
        display: flex;
        align-items: center;
        gap: .2em;
      }
    }
  }

  .button-group {
    display: flex;
    gap: $gap;
    align-items: center;
    justify-content: center;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
      gap: $gap*2;
    }
  }

  h3 {
    max-width: 35ch;
  }

  p,
  h6 {
    max-width: 40ch;
  }

  h1,
  h3,
  p {
    margin-top: 0em;
  }
}

.topics {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tags-topics {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  align-items: center;
  justify-content: center;
  margin: 1em 0em;
}

.book {
  @include flex(row);
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: var(--max-width-container);

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.training {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-top: 4em;

  h6{
    text-align: center;
  }

  h1,p,h6{
    text-align: center;
  }
}

.calendly {
  min-width: 320px;
  width: -webkit-fill-available;
  height: 700px;
  border: 1px solid var(--border-color);
  border-radius: 1em;
  overflow: hidden;
  background-color: var(--grey-100);
}

.primary {
  background: var(--grey-600);
  color: var(--grey-100);
  border: none;
}

.company-form-container {
  width: -webkit-fill-available;
}

form {
  width: -webkit-fill-available;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $gap;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    padding: 0 1.5em;
  }

  input,
  textarea {
    padding: 1.25em;
    border-radius: .75em;
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    font-size: 1.2em;

    &:focus-visible {
      outline: transparent;
      border: 1px solid var(--highlight-color);
    }
  }

  textarea {
    grid-column: 1/3;

    @media (max-width: 800px) {
      grid-column: 1/1;
    }
  }

  input {
    width: 100%;
    padding: 1.25em;
    border-radius: $border-radius;
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    // font-size: 1.2em;

    &:focus-visible {
      outline: none;
      border-color: 1px solid var(--highlight-color);
    }
  }

  .submit-button {
    grid-column: 1 / -1;
  }
}