.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--width-container);
  max-width: var(--max-width-container);
  margin: 0 auto;
  padding: 4em 0;

  @media (max-width: 800px) {
    width: 100%;
  }

  h1 {
    margin: 0;
  }

  .tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    padding: 1em 0;
    align-items: center;
    justify-content: center;
    flex-basis: 25%;
  }

  form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      width: -webkit-fill-available;
      padding: 0 1.5em;
    }

    input {
      width: 100%;
    }

    textarea {
      max-width: 100%;
      grid-column: 1 / -1;
    }

    @media (min-width: 901px) {
      .input-3 {
        grid-row: 3/3;
        grid-column: 1/2;
      }

      .textarea-full {
        grid-column: 2/2;
        grid-row: 1/4;
      }
    }

    input,
    textarea {
      padding: 1.25em;
      border-radius: .75em;
      border: 1px solid var(--border-color);
      box-sizing: border-box;
      font-size: 1.2em; 

      &:focus-visible {
        outline: transparent;
        border: 1px solid var(--highlight-color);
      }
    }

    .submit-button {
      grid-column: 1/-1;
    }

    .contact-form-submit:hover {
      background-color: var(--highlight-color-high);
    }
  }
}

.status-message {
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  animation: fadeInOut 3s ease-in-out;
}

.status-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.status-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}