.community-file-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
    text-decoration: none;

    .image-stack-container {
        width: 96%;
        position: relative;
        padding-top: .5em;
        padding-bottom: .5em;
        margin-bottom: 1px; // Connect with content section
        transition: transform 0.3s ease-out;
        z-index: -10;
        transform: rotate(var(--random-rotation)); // Will be set by inline style in React

        &:hover {
            transform: rotate(0deg) !important;

            .stacked-bg-card {
                transform: rotate(0deg) !important;
                
                &.secondary {
                    transform: rotate(0deg) !important;
                }
            }
        }

        // Background stacked cards
        .stacked-bg-card {
            position: absolute;
            top: -.5em;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 0.75em;
            background-color: #f2e0cb;
            transform: rotate(-1deg); // Adjusted to be subtle
            transition: transform 0.3s ease-out;
            z-index: 1;

            &.secondary {
                background-color: #f9eed4;
                top: -.25em;
                transform: rotate(1deg); // Adjusted to be subtle
                z-index: 2;
            }
        }

        // Main image container
        .main-image-container {
            position: relative;
            z-index: 3;
            background-color: white;
            border-radius: 0.75em;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
                display: block;
                border-radius: 0.75em;
            }
        }
    }

    // Content section
    .community-content {
        display: flex;
        flex-direction: column-reverse;
        gap: .5em;
        align-items: center;
        width: -webkit-fill-available;
        background-color: white;
        padding: 1.5em;
        border: 1px solid var(--border-color);
        box-shadow: 0px 1px 6px 1px #00000014;
        border-radius: .25em .25em 0.75em 0.75em;
        z-index: 100;

        h6 {
            color: var(--primary-text);
            margin: 0;
            text-align: center;
            display: none;
        }

        h5 {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .2em;
            margin: 0;
        }
    }
}