@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.tag-parent {
  display: flex;
  height: fit-content;
  flex-direction: row;
  align-items: center;
  gap: .75em;
  padding: .25em .75em;
  border-radius: 5em;
  border: .5px solid var(--border-color);

  &.nobg {
    background-color: transparent !important;

    .tag {
      font-weight: 600;
    }
  }

  &.small {
    gap: .5em;
    padding: .25em .75em;

    .dot {
      width: .4em;
      height: .4em;
    }

    .tag {
      font-size: 1.15em;
    }
  }

  .dot {
    width: .6em;
    height: .6em;
    border-radius: 2em;

    &.pulsating {
      animation: pulsate 1.5s ease-in-out infinite;
    }
  }

  .tag {
    font-size: 1.25em;
    letter-spacing: -.5px;
    transition: transform 0.3s ease;
    margin: 0;

    &:hover {
      transform: rotate(0deg);
    }
  }
}