.project-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    height: auto;
    transition: all linear 0.2s;
    // padding: 1em;
        // border: 1px solid var(--border-color);
        // border-radius: .75em;
        // background-color: var(--grey-100);

    &:hover {
        cursor: pointer;
        background-color: var(--grey-100);
        scale: .995;
    }

    img {
        width: 100%;
        height: auto;
        display: block;
        object-fit: cover;
        border-radius: 0.75em;
        transform-origin: center;
        border: 1px solid var(--border-color);
    }

    .project-card {
        width: -webkit-fill-available;
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 1em;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            justify-content: start;
            align-items: start;
        }

        .project-card-data {
            width: -webkit-fill-available;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-between;
            text-decoration: none;
            gap: .2em;

            .title{
                width: 100%;
                display: flex;
                flex-direction: column-reverse;
                justify-content: space-between;

                h5{
                    font-family: 'Circular-Book', 'Arial Narrow', Arial, sans-serif;
                    text-transform: uppercase;
                    font-size: 1em;
                }

                @media screen and (max-width: 900px) {
                    flex-direction: column-reverse;
                    justify-content: start;
                    align-items: start;
                }

                .tag-container {
                    display: flex;
                    gap: 0.3em;
                }
            }

            .link{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: end;

                @media screen and (max-width: 900px) {
                    // flex-direction: column;
                    // justify-content: start;
                    // align-items: start;
                }

                .read-more-button{
                    @media screen and (max-width: 900px) {
                        // display: none;
                    }
                }
            }

            h2, h3, h5, h6, p {
                margin: 0;
            }

            h3 {
                font-size: 1.6em;
                font-family: "Circular-Book", Helvetica, sans-serif;
                max-width: 35ch;
                margin-bottom: 0;

                @media screen and (max-width: 767px) {
                    // max-width: 25ch;
                }
            }

            p {
                max-width: 32ch;
                opacity: 90%;
                font-size: 1.2em;
                margin-top: 0;

                @media screen and (max-width: 767px) {
                    max-width: 90%;
                }
            }
        }
    }

    // Styles specific to the small variant
    &.project-container-small {
        flex-direction: row;
        max-width: var(--max-width-container);
        gap: 1em;
        padding: 1em;
        border: 1px solid var(--border-color);
        border-radius: .75em;
        background-color: var(--grey-100);
        height: fit-content;

        img {
            width: 30%;
            // aspect-ratio: 1/1;
            height: 100%;

            @media screen and (max-width: 767px) {
                aspect-ratio: 16/9;
            }

            @media screen and (max-width: 608px) {
                aspect-ratio: 1/1;
                object-position: left;
            }
        }

        .project-card {
            padding-top: 0;

            .project-card-data {
                h3 {
                    max-width: 30ch;
                }

                h5 {
                    text-transform: capitalize;
                    font-size: 1.2em;
                }
            }
        }
    }
}