.carousel-container-figma {
  width: 100vw;
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
  gap: 1.5em;
  padding: 1.25em 0;

  &:hover {
    cursor: pointer;
  }

  .carousel-item {
    display: inline-block;
    width: auto;
    height: auto;
    flex-shrink: 0;
    transition: transform 0.3s ease;
    padding: .5em;
    background-color: var(--grey-100);
    border: 1px solid var(--border-color);
    border-radius: .75em;

    @media(max-width:800px) {
      width: -webkit-fill-available;
    }

    &:hover {
      transform: scale(1.01);
    }

    img {
      width: 100%;
      height: 20em;
      object-fit: cover;
      border-radius: .75em;
    }

    .carousel-text {
      padding: .5em 0;
      white-space: normal;

      h4,
      h5 {
        font-size: 1.2em;
        margin: 0;
      }
    }
  }
}