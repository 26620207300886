.archive-container {
    max-width: 840px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    gap: 1.25em;

    @media (max-width: 1024px) {
        padding: 0 1em;
        gap: 1em;
    }

    .intro {

        h1,
        p {
            text-align: center;
            margin: 0;
        }
    }

    .community-files-grid {}
}