.project-header {
    max-width: var(--max-width-container);
    // min-width: 840px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 2em 0;
    gap: 2em;

    .project-header-image {
        width: inherit;
        border-radius: .75em;
        // max-width: 640px;
        width: 100%;
    }

    .project-header-data {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: .5em;
        // margin-bottom: 4em;

        .tag-container {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            margin-top: 1rem;
          }

        h1 {
            font-size: 3em;
            text-align: center;
            margin: 0;
            max-width: 20ch;
            line-height: 120%;
        }

        .project-tags {
            margin: 0;
            color: var(--grey-300);
        }
    }
}

@media (max-width: 1024px) {
    .project-header {
        width: -webkit-fill-available;
        padding: 2em 1em !important;
    }
}

@media (max-width: 768px) {
    .project-header {
        width: -webkit-fill-available;
        padding: 2em 1em;

        .project-header-data {
            h1 {
                width: fit-content;
            }
        }
    }
}

@media (max-width: 480px) {}