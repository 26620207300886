.testimonials-section {
    max-width: var(--max-width-container);
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: auto;
    overflow: hidden;
    align-items: center;
    margin-top: 2em;

    .scroll-container {
        width: 100%;
    }

    h1 {
        text-align: center;
    }

    .testimonials-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
        gap:1em;

        @media (max-width: 677px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: auto auto;
        }
    }

    .testimonial-card {
        min-height: 10em;
        display: flex;
        flex-direction: column;
        background-color: var(--bg-color);
        flex: 0 0 30em;
        border-radius: .75em;
        padding: 1.5em;
        border: 1px solid var(--border-color);
        justify-content: space-between;
        background-color: var(--grey-100);
        gap: 2em;
        transition: transform 0.3s ease, rotate 0.3s ease;

        &:hover {
            rotate: 0deg;
        }

        p {
            margin: 0;
            line-height: 145%;

            .highlighted {
                color: var(--highlight-color);
                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;
                padding: 0 0.1em;
                background: #af6f0018;
                border-radius: 0.2em;
            }
        }
    }

    .testimonial-header {
        display: flex;
        align-items: start;
        gap: .5em;

        img {
            background-color: white;
        }

        .testimonial-meta {

            h4,
            h5 {
                margin: 0;
            }
        }
    }

    .testimonial-avatar {
        width: 3em;
        height: 3em;
        border-radius: 50%;
        object-fit: cover;
        background-color: wheat;
    }
}

@media (max-width: 768px) {
    .testimonials-section {
        padding: 0rem 1rem;
    }
}