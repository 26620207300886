*{
  --bg-select: #fff8eb;
}

.container-nav {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  z-index: 1000;

  .navbar {
    background-color: white;
    position: fixed;
    bottom: 0;
    padding: .5em;
    margin-bottom: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    transition: all 0.3s ease;
    gap: .5em;
    border: 1px solid var(--border-color);

    &.active {
      transform: translateY(0);
    }

    // Styles for main navigation
    &.main-nav {
      min-width: auto;
      box-shadow: 0px 4px 10px rgba(0, 72, 42, 0.2);
      margin: 1em;
      border-radius: 1em;

      @media (max-width: 800px) {
        width: -webkit-fill-available;
        border-radius: .75em;
        margin: 1em;
      }
    }

    // Styles for project navigation
    &.project-nav {
      width: auto;
      gap: 4em;
      box-shadow: 0px 4px 10px rgba(0, 72, 42, 0.2);
      border-radius: .75em;

      .a-header {
        &:hover {
          color: var(--grey-400);
        }

        &.next-project-btn {
          position: relative;
        }
      }
    }
  }

  .a-header {
    font-size: 1.2em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: .5em 1em;
    color: var(--grey-300);
    transition: all 0.3s ease;
    border-radius: .75em;
    transition: all linear .2s;

    @media (max-width: 800px) {
      border-radius: .75em;
      flex-grow: 1;
    }

    // New styles for hiding text on mobile
    .hide-on-mobile {
      @media (max-width: 487px) {
        display: none;
      }
    }

    &:hover {
      color: var(--highlight-color);
      border-radius: .75em;
      background-color: var(--bg-select);

      @media (max-width: 800px) {
        border-radius: .75em;
      }
    }

    &.active {
      color: var(--highlight-color);
      background-color: var(--bg-select);
      border-radius: .75em;

      @media (max-width: 800px) {
        border-radius: .75em;
      }
    }

    &.back-btn {
      color: var(--highlight-color);
      flex-direction: row !important;
      svg {
        transition: transform 0.3s ease;
      }

      &:hover{
        color: var(--highlight-color);
      }

      &:hover svg {
        transform: translateX(-4px);
      }
    }

    &.next-project-btn {
      color: var(--highlight-color);
      flex-direction: row !important;
      svg {
        transition: transform 0.3s ease;
      }

      &:hover{
        color: var(--highlight-color);
      }

      &:hover svg {
        transform: translateX(4px);
      }
    }
  }
}