.gallery-parent {
    // width: var(--width-container);
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-direction: column;
    
    .gallery-grid {
        display: grid;
        gap: 0.5em;
        padding: 1.5em;

    }

    .gallery-content {
        width: -webkit-fill-available;
        // border: 1px solid var(--border-color);
        // background-color: var(--bg-color-high);
        border-radius: 0.5em;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        align-items: center;  
        justify-content: center;  
        gap:1em;

        h1,
        p {
            width: 55%;
            text-align: center;
            margin: 0;

            @media (max-width: 800px) {
                width: 70%;
            }
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    .image-container {
        position: relative;
        overflow: hidden;
        border-radius: 0.5em;
        padding: .5em;
        background-color: var(--grey-100);
        border: 1px solid var(--border-color);
        // box-shadow: 1px 1px 1px 1px #00000014;

        &:hover {
            transform: rotate(0deg) !important;

            .location-text:not(.always-visible) {
                opacity: 1;
            }
        }
    }

    .gallery-image {
        width: 100%;
        height: auto;
        display: block;
    }

    .location-text {
        font-family: 'Architects Daughter', Courier, monospace;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .2em;
        font-size: 1.3em;
        color: var(--secondary-text);
        padding: .5em .75em;
        border-radius: .75em;
        // opacity: 0;
        transition: opacity 0.3s ease;

        &.always-visible {
            opacity: 1;
            top: auto;
            bottom: 10px;
            right: 10px;
        }
    }
}

@media (max-width: 750px) {
    .gallery-parent {
        .location-text {
            opacity: 1;
        }

        h1,
        p {
            width: -webkit-fill-available;
        }

        p {
            width: -webkit-fill-available;
        }
    }
}