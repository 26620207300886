.container-project {
    max-width: var(--max-width-container);
    display: flex;
    flex-direction: column;
    // align-items: center;
    margin: auto;

    .project-details {
        width: var(--max-width-container);

        .red {
            font-size: 1.5em;
            background-color: #d50019;
            color: white;
            width: fit-content;
            padding: .25em;
        }

        section {
            max-width: 70%;
        }

        .stories{
            width: fit-content;
            border-radius: .75em;
            overflow: hidden;
        }

        h3 {
            padding: .75em;
            border-radius: .5em;
            background: #ffffff09;
        }

        h5,
        h6,
        li,
        p {
            font-weight: 500;
        }

        p {
            margin-bottom: .5em;
        }

        blockquote {
            font-family: "Roboto Slab", Helvetica, sans-serif;
            font-size: 1.8em;
            font-weight: 500;
            color: var(--highlight-color);
            padding: .5em; 
            border-left: 4px solid var(--highlight-color);
            background-color: white;

            @media (max-width: 767px) {
                width: 100%;
                margin-left: 0;
                font-size: 1.75em;
              }
        }

        .intro {
            max-width: 640px;
            display: flex;
            align-items: start;
            flex-direction: column;
        }

        .img-container {
            h4 {
                text-transform: uppercase;
            }
        }
    }
}

/* Breakpoints */
@media (max-width: 1024px) {
    .project-details {
        max-width: -webkit-fill-available;

        section {
            max-width: 70% !important;
            padding: 0 1em;
        }

        figure {
            max-width: -webkit-fill-available !important;
            padding: 0 1em;
        }
    }
}

@media (max-width: 768px) {

    .project-details {
        max-width: -webkit-fill-available;

        section {
            width: 85vw;
            min-width: 80vw;
            max-width: 95vw !important;
            padding: 0 1em;
        }

        figure {
            max-width: -webkit-fill-available !important;
            padding: 0em 1em;
        }
    }
}

@media (max-width: 480px) {
    .container-project {
        width: 100%;
    }

    .project-details {
        section {
            max-width: 100%;
        }

        .intro {
            max-width: 100%;
        }
    }
}