.carousel {
    position: relative;
    width: 100vw;
    height: auto;
    overflow: hidden;
    background-color: var(--grey-100);
    padding: 1em;
    
    &-rows {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: .5em;

        @media (max-width:900px) {
            gap: .2em;
        }
    }

    &-row {
        flex: 1;
        overflow: hidden;
    }
}

.row-content {
    display: flex;
    gap: 1em;
    height: 100%;
    will-change: transform;
    transition: transform 0.016s linear;

    @media (max-width:900px) {
        gap: .2em;
    }
}
    
.image-wrapper {
    flex-shrink: 0;
    
    img {
        width: auto;
        height: 25em;
        object-fit: cover;
        transition: all 0.3s ease;
        border: 1px solid var(--border-color);
        border-radius: .75em;
        // padding: .25em;
        overflow: hidden;

        @media (max-width:900px) {
            height: 20em;
        }
        
        &:hover {
            transform: scale(1.01);
        }
    }
}

.gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10em;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &--left {
        left: 0;
        background: linear-gradient(to right, #fcf9f3, #fcf9f300);
    }

    &--right {
        right: 0;
        background: linear-gradient(to left, #fcf9f3, #fcf9f300);
    }
}