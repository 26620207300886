.resume-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.resume-popup-overlay.fade-in {
    opacity: 1;
}

.resume-popup-overlay.fade-out {
    opacity: 0;
}

.resume-popup-content {
    background-color: white;
    padding: 2em;
    padding-bottom: 5em;
    border-radius: 1em 1em 0 0;
    width: auto;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
}

.resume-popup-content.slide-up {
    transform: translateY(0);
    // scale: 1;
}

.resume-popup-content.slide-down {
    transform: translateY(100%);
    // scale: 1.23;
}

.close-button {
    position: fixed;
    bottom: 2em;
    // top: 1em;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    z-index: 11;
}

@media (max-width: 768px) {
    .resume-popup-content {
        padding: 1.5em;
        padding-bottom: 4em;
    }

    .close-button {
        width: 40px;
        height: 40px;
        bottom: 1.5em;
    }
}

@media (max-width: 480px) {
    .resume-popup-content {
        padding: 1em;
        padding-bottom: 3.5em;
    }

    .close-button {
        bottom: 1em;
    }
}