// JobEntry.module.css
.resume {
    width: var(--width-container);
    max-width: var(--max-width-container);
    margin: auto;
    background-color: #fff;
    flex-direction: column;
    gap: 1em;
    border-radius: .75em;
    border: 2px solid var(--border-color);

    @media (max-width : 700px){
        width: 100%;
    }

    .job-entry {
        width: -webkit-fill-available;
        display: flex;
        gap: 1em;
        padding: 1em;
        padding-bottom: 0;

        .company-logo {
            width: 3.5em;
            height: 3.5em;
            background-color: white;
            border-radius: .5em;
            padding: .2em;
            object-fit: contain;
            border: .4px solid var(--border-color);
        }

        .job-details {
            width: 60ch;
            display: flex;
            flex-direction: column;

            h4,
            h5 {
                font-size: 1.3em;
                margin: 0;
            }

            ul {
                padding: 0.5em 0;
                margin: 0 0.5em;

                li {
                    font-size: 1.3em;
                    margin-left: 1em;
                }
            }
        }
    }
}