    .header-details {
        display: flex;
        flex-direction: column;
        height: fit-content;
        align-items: center;
        width: -webkit-fill-available;
        max-width: var(--max-width-container);
        margin: 0 auto;
        margin-top: 0;
        padding: 4em 1.5em;
        padding-bottom: 2em;
        gap: .6em;

        .profile {
            width: 7.5em;
            height: 7.5em;
            // padding: .3em;
            border: 3px solid var(--grey-100);
            background: rgb(252, 249, 243);
            background: linear-gradient(0deg, rgba(252, 249, 243, 1) 0%, rgba(245, 236, 219, 1) 30%, rgba(240, 229, 206, 1) 100%);
            border-radius: 5em;
            object-fit: cover;
            box-shadow: 1px 1px 13px 1px #00000012;
            /* Prevents the image from repeating */
        }

        h1,
        h5,
        h2,
        h3,
        p {
            margin: 0;
            text-align: center;
        }

        h1 {
            font-family: "Circular-Book", Helvetica, sans-serif;
            span {    
                // font-family: "Circular-Medium", Helvetica, sans-serif;
            }
            // @media (max-width:900px) {
            //     font-size: 1.75em;
            // }
        }

        p {
            color: var(--tertiary-text);
            // text-align: center;
        }

        .length-xxs {
            color: var(--secondary-text);
            max-width: 50%;
        }

        .button-group {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            flex-wrap: wrap;
            gap: .5em;
            margin: 1em 0;

            @media screen and (max-width: 767px) {
                gap: 0.5em;
            }
        }

        @media screen and (min-width: 1024px) {}

        /* Medium screens (tablet) */
        @media screen and (max-width: 1023px) and (min-width: 768px) {}

        @media screen and (max-width: 767px) {
            gap: 0.6em;

            .length-xxs {
                max-width: 90%;
            }
        }

        /* Small screens (mobile) */

    }