.custom-button {
  width: auto;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  gap: .5em;
  transition: scale ease-in-out .2s;

  &:hover {
    scale: .99;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
  }

  &.custom-button-left {
    flex-direction: row;
  }

  &.custom-button-right {
    flex-direction: row-reverse;
  }

  // Size variants
  &.custom-button-s {
    padding: 0.5em .75em;
    border-radius: .5em;
  }

  &.custom-button-m {
    padding: .75em 1.25em; // Default medium size
    border-radius: .75em;
  }

  &.custom-button-l {
    padding: 1em 1.5em;
    border-radius: 1em;
  }

  // Variant styles
  &.custom-button-primary {
    background-color: var(--highlight-color);
    color: white;

    &:hover {
      background-color: var(--highlight-color-light);
    }
  }

  &.custom-button-secondary {
    background-color: white;
    color: var(--highlight-color);
    border: 1px solid var(--border-color);

    &:hover {
      background-color: var(--grey-100);
    }
  }

  &.custom-button-tertiary {
    background-color: transparent;
    color: var(--highlight-color);
    padding: .5em !important;

    &:hover {
      background-color: var(--grey-100);
    }
  }

  &.custom-button-link {
    background-color: #28a745;
    color: #ffffff;

    &:hover {
      background-color: #218838;
    }
  }
}